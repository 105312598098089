import React from 'react';
import { LmsPage } from 'features/solutions';
import { Locale } from 'features/localization';
import { PageProps } from 'gatsby';

function Page({ pageContext: { locale } }: PageProps<unknown, { locale: Locale }>) {
  return <LmsPage locale={locale} />;
}

export default Page;
